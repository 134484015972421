<template>
  <div>
    <a-drawer :title="$t('form.makeClaimRequest')" :width="isMobile ? '90%' : '50%'" :visible="drawerVisible"
      :body-style="{ paddingBottom: '80px' }" @close="onClose">
      <div v-if="claimableList.length > 0">
        <a-form :form="form" @submit="onSubmit" layout="vertical">
          <a-form-item :label="$t('form.claimToMember')" style="margin-bottom: 10px">
            <a-cascader :options="formattedOptions" :display-render="displayRender" expand-trigger="hover"
              placeholder="Please select" @change="handleSelectCertificate" v-decorator="[
                'member',
                {
                  rules: [
                    { type: 'array', required: true, message: $t('rules.require') },
                  ],
                },
              ]" />
          </a-form-item>

          <a-row :gutter="24">
            <a-col :span="24" :lg="12">
              <a-form-item :label="$t('form.claimPurpose')">
                <a-select :disabled="isLimit" v-decorator="[
                  'purpose',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('rules.require'),
                      },
                    ],
                  },
                ]" :placeholder="$t('form.choosePurpose')">
                  <a-select-option value="Medicine">
                    {{ $t("form.medicine") }}
                  </a-select-option>
                  <a-select-option value="Checkup">
                    {{ $t("form.checkup") }}
                  </a-select-option>
                  <a-select-option value="Died">
                    {{ $t("form.died") }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="24" :lg="12">
              <a-form-item :label="$t('form.hospitalAndService')">
                <a-select :disabled="isLimit" v-decorator="[
                  'hospitalid',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('rules.require'),
                      },
                    ],
                  },
                ]" :placeholder="$t('form.chooseHospital')">
                  <a-select-option v-for="hospital in hospitals" :key="hospital.id" :value="hospital.id">
                    {{ hospital.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="24" :lg="12">
              <a-form-item :label="$t('form.claimAmount')">
                <a-input-number :disabled="isLimit" style="width: fit-content" :formatter="(value) =>
                  `₭ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  " :parser="(value) => value.replace(/\₭\s?|(,*)/g, '')" v-decorator="[
                    'amount',
                    {
                      rules: [{ required: true, message: $t('rules.require') }],
                    },
                  ]" />
              </a-form-item>
            </a-col>
            <!-- <a-col :span="24" :lg="12">
              <a-form-item :label="$t('form.claimDate')">
                <a-date-picker :disabled="true" format="YYYY-MM-DD HH:mm:ss" style="width: 100%"
                  :get-popup-container="(trigger) => trigger.parentNode"
                  v-decorator="['claim_date', { initialValue: todayDate }]" />
              </a-form-item>
            </a-col> -->
          </a-row>
          <h6>{{ $t("form.document") }}:</h6>
          <WidgetAddMultiPhoto :disabled="isLimit" @upload="onUploadPhoto" />
          <a-row :gutter="24">
            <a-col :span="24">
              <a-form-item :label="$t('label.claimDescription')">
                <a-textarea v-decorator="['description']" :rows="4" :placeholder="$t('form.enterDescription')" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div v-else class="no-claim">
        <a-empty :description="$t('label.noClaimAvailable')" />
      </div>
      <div class="drawer-footer" :style="{}">
        <a-button :style="{ marginRight: '8px' }" @click="onClose">
          {{ $t("button.cancel") }}
        </a-button>
        <a-button type="primary" @click="onSubmit">
          {{ $t("button.submit") }}
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import moment from "moment";
import WidgetAddMultiPhoto from "../Widgets/WidgetAddMultiPhoto.vue"
import DataService from "../../services/data.service";
import ClaimService from "../../services/claim.service";
import { message } from "ant-design-vue";

export default {
  name: "DrawerAddClaim",
  components: {
    WidgetAddMultiPhoto
  },
  props: {
    drawerVisible: Boolean,
    default: false,
    claimableList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: null,
      visible: this.drawerVisible,
      isMobile: false,
      todayDate: moment(),
      photo: [],
      hospitals: '',
      isLimit: false
    };
  },

  watch: {
    drawerVisible(newValue) {
      this.visible = newValue;
    },
  },

  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.form = this.$form.createForm(this);
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    formattedOptions() {
      return this.claimableList.map(item => ({
        value: item.id,
        label: item.no,
        children: item.certificatemember.map(member => ({
          value: member.id,
          label: `${member.firstname} ${member.lastname}`,
        })),
      }));
    },
  },

  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 768;
    },
   async handleSelectCertificate(value) {
      const [certificateid, certificatememberid] = value
      const checkClaim = {certificateid, certificatememberid}
    await ClaimService.checkClaimLimit(checkClaim).then(res =>{
        if(res.data !='claimable'){
          this.isLimit = true
          this.$message.error(`This certificate already claimed more than 3 times!`);
        }
        else {
          this.isLimit = false
        }
      }).catch((err) => {
          message.error(err.message);
        })
    },
    onUploadPhoto(val) {
      this.photo = val
    },
    async getHospitals() {
      await DataService.getHospitals()
        .then((res) => {
          this.hospitals = res.data;
        })
        .catch((err) => {
          message.error(err.message);
        })
    },
   
    displayRender({ labels }) {
      return labels[labels.length - 1]; // Show the last label (name)
    },

    onClose() {
      this.visible = false;
      this.$emit("close");
    },
    onSubmit(e) {
      e.preventDefault();
      this.form.validateFields((errors, values) => {
        if (this.photo.length < 1) {
          return this.$message.error(`Please upload document's photo`);
        }
        if (!errors) {
          const photo = this.photo
          const [certificateid, certificatememberid] = values.member;
          const claimDetail = {
            ...values,
            certificateid,
            certificatememberid,
            photo
          };
          this.$confirm({
            title: 'Do you Want to make this claim?',
            onOk:() => {
              ClaimService.addClaim(claimDetail)
              this.form.resetFields();
              this.$emit("makeClaim")
              this.onClose();
              this.$message.success("Make a claim request success!")
            },
            onCancel() {
              console.log('');
            },
            class: 'test',
          });
        }
      });
    },
  },
  created() {
    this.getHospitals()
  }
};
</script>

<style lang="scss" scoped>
.drawer-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
  z-index: 1;
}

.no-claim {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  // width: 100%;
  font-size: 24px;
  font-weight: 600;
}
</style>