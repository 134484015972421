import {baseUrl} from '../utils/helper';
import axios from './axios-interceptor';

class ClaimService {
    getClaims(){
      return axios.get(`${baseUrl}/claim/gets`)
    }
    getClaimById(id){
      return axios.get(`${baseUrl}/claim/get/${id}`)
    }
    getClaimLog(){
      return axios.get(`${baseUrl}/claim/getlogs/${id}`)
    }
    getClaimHistory(data){
      return axios.post(`${baseUrl}/claim/history`, data)
    }
    getClaimableCertificate(data){
      return axios.post(`${baseUrl}/certificate/claimable`, data)
    }
    addClaim(data){
      return axios.post(`${baseUrl}/claim/request`, data)
    }
    checkClaimLimit(data){
      return axios.post(`${baseUrl}/claim/check/limit`, data)
    }
  }
  
  export default new ClaimService();