<template>
    <div class="clearfix">
        <a-upload :fileList="photo" :action="uploadTo" :headers="authHeader" :max-count="20" :multiple="true"
            list-type="picture-card" name="file" @preview="handlePreview" @change="onUploadPhoto"
            >
            <div v-if="photo.length < 20">
                <a-icon type="plus" />
          <!-- <div class="ant-upload-text">Upload</div> -->
                <!-- <a-button> <a-icon type="plus" /> {{ uploadText }} </a-button> -->
            </div>
        </a-upload>
        <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="alt-example" style="width: 100%" :src="previewImage" />
        </a-modal>
    </div>
</template>
<script>
import { uploadUrl } from '../../utils/helper';
import { lsread } from '../../utils/storage';
import { message } from "ant-design-vue";
const auth = lsread('user')
const authHeader = {
    'Authorization': `Bearer ${auth.token}`
}

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}
export default {
    props: {
        uploadText: '',
    },
    name: "WidgetAddPhoto",
    data() {
        return {
            previewVisible: false,
            previewImage: "",
            uploadTo: uploadUrl,
            photo: [],
            authHeader: authHeader,
        };
    },
    methods: {
        handleCancel() {
            this.previewVisible = false;
        },
        async handlePreview(file) {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            this.previewImage = file.url || file.preview;
            this.previewVisible = true;
        },
        async onUploadPhoto(info) {
            this.photo = [...info.fileList];
            if (info.file.status === 'error') {
                this.$message.error(`${info.file.name} file upload failed.`);
            }
            if (info.file.status === 'done') {
                let fileList = this.photo;
                this.$emit("upload", fileList.map((file) => file.response ? file.response.url : ''));
            }

        },
    },
};
</script>