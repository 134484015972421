import {baseUrl} from '../utils/helper';
import axios from './axios-interceptor';

class DataService {
    getHospitals(){
      return axios.get(`${baseUrl}/hospitals`)
    }
    getHospitalById(id){
      return axios.get(`${baseUrl}/hospital/${id}`)
    }
    getServiceUnits(){
      return axios.get(`${baseUrl}/services`)
    }
    getProvinces(){
      return axios.get(`${baseUrl}/provinces`)
    }
  }
  
  export default new DataService();