import axios from 'axios';
import authHeader from './auth-header';

axios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      config.headers = { ...config.headers, ...authHeader() };
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  
  // Export axios instance with interceptors
  export default axios;