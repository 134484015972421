<template>
  <div>
    <div v-if="isLoading">
      <Loading />
    </div>
    <div v-else class="p-30">
      <div style="text-align: right;">
        <a-button icon="plus" class="mb-25" type="primary" slot="overlay" @click="checkClaimableCertificate">
         {{ $t("label.claimRequest") }}
      </a-button>
      </div>
      <div v-if="claimsList && claimsList.length > 0">
        <a-page-header v-for="item in claimsList" v-bind:key="item.id" v-bind:title="item.type" class="claim-list">
          <template slot="tags">
            <a-tag :color="statusTagColor(item.status)">
              {{ item.status }}
            </a-tag>
          </template>
          <a-row :gutter="18">
            <a-col :span="18" :md="9" :xl="6">
              <a-statistic class="mt-15" :title="$t('label.amount')" v-bind:value="formatMoney(item.amount)"
                valueStyle=" font-family:'NotoSans'; font-size: 20px">
              </a-statistic>
            </a-col>
            <a-col :span="18" :md="9" :xl="6">
              <a-statistic class="mt-15" :title="$t('label.claimTo')"
                v-bind:value="item.certificatemember.firstname + ' ' + item.certificatemember.lastname"
                valueStyle=" font-family:'NotoSans'; font-size: 20px " />
            </a-col>
            <a-col :span="18" :md="9" :xl="6">
              <a-statistic class="mt-15" :title="$t('label.claimDate')" v-bind:value="formatDate(item.reqtime)"
                valueStyle=" font-family:'NotoSans'; font-size: 20px " />
            </a-col>
          </a-row>
          <div class="view-button">
            <a-button icon="eye" v-on:click="viewDetails(item.id)">
              {{ $t("action.viewButton") }}
            </a-button>
          </div>
        </a-page-header>
      </div>

      <div v-else class="no-claim">
        <a-empty :description="$t('label.noClaimRequest')" />
      </div>

      <!-- <a-button class="mt-25" type="primary" slot="overlay" @click="checkClaimableCertificate">
        <a-icon type="plus" /> {{ $t("label.claimRequest") }}
      </a-button> -->
      <DrawerAddClaim 
        :drawerVisible="drawerVisible" 
        :claimableList="claimableList" 
        @close="onClose"
        @makeClaim="onMakeClaim" />
    </div>
  </div>
</template>

<script>
import { message } from "ant-design-vue";
import DrawerAddClaim from "../../components/Drawers/DrawerAddClaim.vue";
import ClaimService from "../../services/claim.service";
import { statusTagColor, formatMoney, formatDate } from "../../utils/helper";
import Loading from "../../components/Loading/Loading.vue";

export default {
  name: "Claim",

  components: {
    DrawerAddClaim,
    Loading
  },

  data() {
    return {
      drawerVisible: false,
      claimsList: null,
      claimableList: [],
      isLoading: false,
    };
  },
  created() {
    this.getClaims()
  },

  methods: {
    async getClaims() {
      this.isLoading = true
      await ClaimService.getClaims()
        .then((res) => {
          this.claimsList = res.data;
          this.isLoading = false
        })
        .catch((err) => {
          message.error(err.message);
          this.isLoading = false
        })
    },
    async checkClaimableCertificate() {
      await ClaimService.getClaimableCertificate()
        .then((res) => {
          this.claimableList = res.data;
          if (this.claimableList.length > 0) {
            this.drawerVisible = true
          }
          else {
            return message.warn("There is no claimable certificate")
          }
        })
        .catch((err) => {
          message.error(err.message);
        })
    },
    async onMakeClaim() {
      this.getClaims()
    },
    statusTagColor,
    formatMoney,
    formatDate,
    onClose() {
      this.drawerVisible = false;
    },
    viewDetails(id) {
      this.$router.push(`/claim/detail/${id}`)

    },
  },
};
</script>

<style lang="scss" scoped>
.claim-list {
  border: 1px solid rgb(235, 237, 240);
  margin-bottom: 20px;
  box-shadow: 0.5px 4px 6px rgba(0, 0, 0.12, 0.12);
  position: relative;
  transition: background-color 0.3s;
  opacity: 1;

  .view-button {
    text-align: right;
    color: green;
  }
}

.claim-list:hover {
  box-shadow: 0 0 10px gray;
  opacity: 0.8;
  transition: opacity 0.3s;
}

.no-claim {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  // width: 100%;
  font-size: 24px;
  font-weight: 600;
}
</style>
